<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      {{ route.query.type ? route.query.type : '林草保护' }}
      <span>/</span>
      正文
    </div>
    <div class="content">
      <Detail :info="info"></Detail>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import Detail from '../components/Detail'
import { getAction } from '../api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'NewsDetail',
  components: {
    Detail
  },
  setup () {
    const info = ref({})
    const route = useRoute()
    const url = ref('')
    watch(() => route.query.id, () => {
      if (route.query.type === '专题列表') {
        url.value = '/yncy/columnList/queryById'
      } else if (route.query.type === '意见征集') {
        url.value = '/yncy/interactive/opinion/queryById'
      } else if (route.query.type === '搜索') {
        url.value = '/yncy/search/queryById'
      } else {
        url.value = '/yncy/grassProtect/queryById'
      }
      getAction(url.value, { id: route.query.id }).then(res => {
        console.log(res)
        info.value = {
          ...res.result,
          name: res.result.subject
        }
      })
    }
    )
    onMounted(() => {
      if (route.query.type === '专题列表') {
        url.value = '/yncy/columnList/queryById'
      } else if (route.query.type === '意见征集') {
        url.value = '/yncy/interactive/opinion/queryById'
      } else if (route.query.type === '搜索') {
        url.value = '/yncy/search/queryById'
      } else {
        url.value = '/yncy/grassProtect/queryById'
      }
      getAction(url.value, { id: route.query.id }).then(res => {
        console.log(res)
        info.value = {
          ...res.result,
          name: res.result.subject?res.result.subject:res.result.title
        }
      })
    })
    return { info, route }
  }
})
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 70px;
}
</style>
